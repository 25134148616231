import BaseImage from 'components/image';
import logo from './png/logo.png';
import banner from './png/image_banner.png';
import machijikan from './webp/image_machijikan.webp';
import kantan from './webp/image_kantan.webp';
import karte from './webp/image_karte.webp';
import icon_OK from './svg/icon_OK.svg';
import icon_OK_b from './svg/icon_OK_b.svg';
import icon_OK_s from './svg/icon_OK_s.svg';
import icon_OK_d from './svg/icon_OK_d.svg';
import icon_NG from './svg/icon_NG.svg';
import icon_NG02 from './svg/icon_NG02.svg';
import shaken from './svg/icon_shaken.svg';
import linkL from './svg/icon_link_l.svg';
import linkLW from './svg/icon_link_l_w.svg';
import linkLNv from './svg/icon_link_l_nv.svg';
import linkR from './svg/icon_link_r.svg';
import linkRW from './svg/icon_link_r_w.svg';
import linkRNv from './svg/icon_link_r_nv.svg';
import closeModal from './svg/icon_close_modal.svg';
import closeSp from './svg/icon_close_sp_02.svg';
import close01 from './svg/icon_close01.svg';
import open01 from './svg/icon_open01.svg';
import back from './svg/icon_back.svg';
import next from './svg/icon_next.svg';
import month12 from './svg/icon_12month.svg';
import satei from './svg/icon_satei.svg';
import ippan from './svg/icon_ippan.svg';
import sensha from './svg/icon_sensha.svg';
import refresh from './svg/icon_refresh.svg';
import seibi from './svg/icon_seibi.svg';
import oil from './svg/icon_oil.svg';
import tire from './svg/icon_tire.svg';
import tireWh from './svg/icon_tire_wh.svg';
import tire02 from './svg/icon_tire_02.svg';
import tire02Wh from './svg/icon_tire_02_wh.svg';
import driveRecorder from './svg/icon_drive_recorder.svg';
import battery from './svg/icon_battery.svg';
import wiper from './svg/icon_wiper.svg';
import bronze from './png/icon_bronze.png';
import sapphire from './png/icon_sapphire.png';
import diamond from './png/icon_diamond.png';
import pulldown from './svg/icon_pulldown.svg';
import cal from './svg/icon_cal.svg';
import mapNv from './svg/icon_map_nv.svg';
import mapBu from './svg/icon_map_bu.svg';
import oilBnr from './png/image_oil_bnr.png';
import alert from './svg/icon_alert.svg';
import shakenWh from './svg/icon_shaken_wh.svg';
import oilWh from './svg/icon_oil_wh.svg';
import driveRecorderWh from './svg/icon_drive_recorder_wh.svg';
import batteryWh from './svg/icon_battery_wh.svg';
import wiperWh from './svg/icon_wiper_wh.svg';
import forbid from './svg/icon_forbid.svg';
import imgEC from './webp/image_EC.webp';
import imgFreight from './webp/image_Freight_vehicle.webp';
import imgImported from './webp/image_Imported_car.webp';
import imgModified from './webp/image_modified_car.webp';
import imgSpecial from './webp/image_special_vehicle.webp';
import imaMotorcycle from './webp/image_motorcycle.webp';
import filterAir from './svg/icon_filter_air.svg';
import tireAll from './svg/icon_tire_all.svg';
import tireAllWh from './svg/icon_tire_all_wh.svg';
import winter from './svg/icon_winter.svg';
import month12Wh from './svg/icon_12month_wh.svg';
import sateiWh from './svg/icon_satei_wh.svg';
import ippanWh from './svg/icon_ippan_wh.svg';
import senshaWh from './svg/icon_sensha_wh.svg';
import refreshWh from './svg/icon_refresh_wh.svg';
import seibiWh from './svg/icon_seibi_wh.svg';
import lock from './svg/icon_lock.svg';
import timeBronze from './png/icon_time_bronze.png';
import timeSapphire from './png/icon_time_sapphire.png';
import timeDiamond from './png/icon_time_diamond.png';
import memberCard from './webp/AB_card.webp';
import memberCardPc from './png/image_membercard_pc.png';
import memberCardSp from './png/image_membercard_sp.png';
import memberCardTb from './png/image_membercard_tb.png';
import groupCard from './png/image_visa.png';
import groupCardPc from './png/image_groupcard_pc.png';
import groupCardSp from './png/image_groupcard_sp.png';
import groupCardTb from './png/image_groupcard_tb.png';
import receipt from './png/image_receipt.png';
import receiptPc from './png/image_receipt_pc.png';
import receiptSp from './png/image_receipt_sp.png';
import receiptTb from './png/image_receipt_tb.png';
import app from './webp/image_app.webp';
import appPc from './webp/image_app_pc.webp';
import appSp from './webp/image_app_sp.webp';
import appTb from './webp/image_app_tb.webp';
import memberCardAll from './png/image_member_card_all.png';
import aboutKarte1 from './webp/image_about_karte1.webp';
import aboutKarte2 from './webp/image_about_karte2.webp';
import aboutKarte2Tb from './webp/image_about_karte_tb.webp';
import aboutKarte2SP from './webp/image_about_karte2_sp.webp';
import appIcon from './png/app_icon.png';
import imageQR from './png/image_QR.png';
import sp1 from './webp/image_sp_01_half.webp';
import sp2 from './webp/image_sp_02_half.webp';
import sp3 from './webp/image_sp_03_half.webp';
import filterOil from './svg/icon_filter_oil.svg';
import filterAircon from './svg/icon_filter_aircon.svg';
import filterAirconWh from './svg/icon_filter_aircon_wh.svg';
import tenken from './svg/icon_tenken.svg';
import tenkenWh from './svg/icon_tenken_wh.svg';
import kaitoriSatei from './svg/icon_kaitori_satei.svg';
import kaitoriSateiWh from './svg/icon_kaitori_satei_wh.svg';
import atf from './svg/icon_atf.svg';
import charactorMan from './webp/image_charactor_man.webp';
import charactorWoman from './webp/image_charactor_woman.webp';
import halfSp from './webp/image_sp_half.webp';
import halfSpGif from './gif/image_sp_half.gif';
import fullSp from './webp/image_sp_01.webp';
import fullSpGif from './gif/image_sp_01.gif';
import halfSp1 from './webp/image_sp_half.webp';
import halfSp2 from './png/image_sp1_half1.png';
import karteCar from './png/image_kjarte_car.png';
import topArrow from './png/top_arrow.png';
import topArrowGif from './gif/top_arrow.gif';
import topArrowGifCenter from './gif/top_arrow_center.gif';
import topArrowGifBotom from './gif/top_arrow_bottom.gif';
import karteCarAll from './gif/karte_car_all.gif';
import karteCarAllSp from './gif/karte_car_all_sp.gif';
import floatingChat from './png/floating_chat.png';
import bnrTopPc from './webp/bnr_top_pc.webp';
import bnrTopSp from './webp/bnr_top_sp.webp';
import car from './webp/car.webp';
import car_all from './gif/car_all.gif';
import car_a from './gif/car_a.gif';
import car_a_t from './gif/car_a_t.gif';
import car_a_w from './gif/car_a_w.gif';
import car_a_w_t from './gif/car_a_w_t.gif';
import car_b from './gif/car_b.gif';
import car_b_a from './gif/car_b_a.gif';
import car_b_a_t from './gif/car_b_a_t.gif';
import car_b_a_w from './gif/car_b_a_w.gif';
import car_b_a_w_t from './gif/car_b_a_w_t.gif';
import car_b_t from './gif/car_b_t.gif';
import car_b_w from './gif/car_b_w.gif';
import car_b_w_t from './gif/car_b_w_t.gif';
import car_e_a_t from './gif/car_e_a_t.gif';
import car_e_a_w_t from './gif/car_e_a_w_t.gif';
import car_e_a_w from './gif/car_e_a_w.gif';
import car_e_a from './gif/car_e_a.gif';
import car_e_b_a_t from './gif/car_e_b_a_t.gif';
import car_e_b_a_w from './gif/car_e_b_a_w.gif';
import car_e_b_a from './gif/car_e_b_a.gif';
import car_e_b_t from './gif/car_e_b_t.gif';
import car_e_b_w_t from './gif/car_e_b_w_t.gif';
import car_e_b_w from './gif/car_e_b_w.gif';
import car_e_b from './gif/car_e_b.gif';
import car_e_t from './gif/car_e_t.gif';
import car_e_w_t from './gif/car_e_w_t.gif';
import car_e_w from './gif/car_e_w.gif';
import car_e from './gif/car_e.gif';
import car_t from './gif/car_t.gif';
import car_w_t from './gif/car_w_t.gif';
import car_w from './gif/car_w.gif';

import car_all_img from './webp/car_all.webp';
import car_a_img from './webp/car_a.webp';
import car_a_t_img from './webp/car_a_t.webp';
import car_a_w_img from './webp/car_a_w.webp';
import car_a_w_t_img from './webp/car_a_w_t.webp';
import car_b_img from './webp/car_b.webp';
import car_b_a_img from './webp/car_b_a.webp';
import car_b_a_t_img from './webp/car_b_a_t.webp';
import car_b_a_w_img from './webp/car_b_a_w.webp';
import car_b_a_w_t_img from './webp/car_b_a_w_t.webp';
import car_b_t_img from './webp/car_b_t.webp';
import car_b_w_img from './webp/car_b_w.webp';
import car_b_w_t_img from './webp/car_b_w_t.webp';
import car_e_a_t_img from './webp/car_e_a_t.webp';
import car_e_a_w_t_img from './webp/car_e_a_w_t.webp';
import car_e_a_w_img from './webp/car_e_a_w.webp';
import car_e_a_img from './webp/car_e_a.webp';
import car_e_b_a_t_img from './webp/car_e_b_a_t.webp';
import car_e_b_a_w_img from './webp/car_e_b_a_w.webp';
import car_e_b_a_img from './webp/car_e_b_a.webp';
import car_e_b_t_img from './webp/car_e_b_t.webp';
import car_e_b_w_t_img from './webp/car_e_b_w_t.webp';
import car_e_b_w_img from './webp/car_e_b_w.webp';
import car_e_b_img from './webp/car_e_b.webp';
import car_e_t_img from './webp/car_e_t.webp';
import car_e_w_t_img from './webp/car_e_w_t.webp';
import car_e_w_img from './webp/car_e_w.webp';
import car_e_img from './webp/car_e.webp';
import car_t_img from './webp/car_t.webp';
import car_w_t_img from './webp/car_w_t.webp';
import car_w_img from './webp/car_w.webp';

import loading from './gif/apros_loading03.gif';
import maintenance from './webp/image_maintenance.webp';
import pinA from './svg/icon_pin_a.svg';
import pinD from './svg/icon_pin_d.svg';
import check from './svg/icon_check.svg';
import pc_bronze_reg_bg_2px from './png/pc_bronze_bg_2.png';
import pc_sapphire_reg_bg_2px from './png/pc_sapphire_bg_2.png';
import pc_diamond_reg_bg_2px from './png/pc_diamond_bg_2.png';
import sp_bronze_reg_bg_2px from './png/sp_bronze_bg_2.png';
import sp_sapphire_reg_bg_2px from './png/sp_sapphire_bg_2.png';
import sp_diamond_reg_bg_2px from './png/sp_diamond_bg_2.png';
import tb_bronze_reg_bg_2px from './png/tb_bronze_bg_2.png';
import tb_sapphire_reg_bg_2px from './png/tb_sapphire_bg_2.png';
import tb_diamond_reg_bg_2px from './png/tb_diamond_bg_2.png';

import pc_bronze_reg_bg_1px from './png/pc_bronze_bg_1.png';
import pc_sapphire_reg_bg_1px from './png/pc_sapphire_bg_1.png';
import pc_diamond_reg_bg_1px from './png/pc_diamond_bg_1.png';
import sp_bronze_reg_bg_1px from './png/sp_bronze_bg_1.png';
import sp_sapphire_reg_bg_1px from './png/sp_sapphire_bg_1.png';
import sp_diamond_reg_bg_1px from './png/sp_diamond_bg_1.png';
import tb_bronze_reg_bg_1px from './png/tb_bronze_bg_1.png';
import tb_sapphire_reg_bg_1px from './png/tb_sapphire_bg_1.png';
import tb_diamond_reg_bg_1px from './png/tb_diamond_bg_1.png';

import bnr_center from './png/center_pc.jpeg';
import bnr_right from './png/right_pc.jpeg';
import bnr_left from './png/left_pc.jpeg';

export const Image = ({ src, ...rest }) => <BaseImage src={src} {...rest} />;

const Icons = {
  Image,
  logo,
  icon_OK,
  icon_OK_b,
  icon_OK_s,
  icon_OK_d,
  icon_NG,
  icon_NG02,
  shaken,
  shakenWh,
  linkL,
  linkLW,
  linkR,
  linkRW,
  linkLNv,
  linkRNv,
  closeModal,
  closeSp,
  back,
  next,
  month12,
  satei,
  ippan,
  sensha,
  refresh,
  seibi,
  oil,
  oilWh,
  tire,
  driveRecorder,
  driveRecorderWh,
  battery,
  batteryWh,
  wiper,
  wiperWh,
  banner,
  bronze,
  sapphire,
  diamond,
  pulldown,
  tireWh,
  tire02,
  tire02Wh,
  cal,
  machijikan,
  kantan,
  karte,
  mapNv,
  mapBu,
  oilBnr,
  alert,
  forbid,
  imgEC,
  imgFreight,
  imgImported,
  imgModified,
  imgSpecial,
  imaMotorcycle,
  filterAir,
  tireAll,
  tireAllWh,
  winter,
  month12Wh,
  sateiWh,
  ippanWh,
  senshaWh,
  refreshWh,
  seibiWh,
  lock,
  timeBronze,
  timeSapphire,
  timeDiamond,
  memberCard,
  memberCardPc,
  memberCardSp,
  memberCardTb,
  groupCard,
  groupCardPc,
  groupCardSp,
  groupCardTb,
  receipt,
  receiptPc,
  receiptSp,
  receiptTb,
  app,
  appPc,
  appSp,
  appTb,
  memberCardAll,
  aboutKarte1,
  aboutKarte2,
  aboutKarte2Tb,
  aboutKarte2SP,
  appIcon,
  imageQR,
  sp1,
  sp2,
  sp3,
  filterOil,
  filterAircon,
  filterAirconWh,
  atf,
  charactorMan,
  charactorWoman,
  halfSp,
  halfSpGif,
  fullSp,
  fullSpGif,
  halfSp1,
  halfSp2,
  karteCar,
  tenken,
  tenkenWh,
  kaitoriSatei,
  kaitoriSateiWh,
  topArrow,
  topArrowGif,
  topArrowGifCenter,
  topArrowGifBotom,
  karteCarAll,
  karteCarAllSp,
  open01,
  close01,
  floatingChat,
  loading,
  bnrTopPc,
  bnrTopSp,
  car,
  car_all,
  car_a,
  car_a_t,
  car_a_w,
  car_a_w_t,
  car_b,
  car_b_a,
  car_b_a_t,
  car_b_a_w,
  car_b_a_w_t,
  car_b_t,
  car_b_w,
  car_b_w_t,
  car_e_a_t,
  car_e_a_w_t,
  car_e_a_w,
  car_e_a,
  car_e_b_a_t,
  car_e_b_a_w,
  car_e_b_a,
  car_e_b_t,
  car_e_b_w_t,
  car_e_b_w,
  car_e_b,
  car_e_t,
  car_e_w_t,
  car_e_w,
  car_e,
  car_t,
  car_w_t,
  car_w,
  car_all_img,
  car_a_img,
  car_a_t_img,
  car_a_w_img,
  car_a_w_t_img,
  car_b_img,
  car_b_a_img,
  car_b_a_t_img,
  car_b_a_w_img,
  car_b_a_w_t_img,
  car_b_t_img,
  car_b_w_img,
  car_b_w_t_img,
  car_e_a_t_img,
  car_e_a_w_t_img,
  car_e_a_w_img,
  car_e_a_img,
  car_e_b_a_t_img,
  car_e_b_a_w_img,
  car_e_b_a_img,
  car_e_b_t_img,
  car_e_b_w_t_img,
  car_e_b_w_img,
  car_e_b_img,
  car_e_t_img,
  car_e_w_t_img,
  car_e_w_img,
  car_e_img,
  car_t_img,
  car_w_t_img,
  car_w_img,
  maintenance,
  pinA,
  pinD,
  check,
  // 2px枠
  pc_bronze_reg_bg_2px,
  pc_sapphire_reg_bg_2px,
  pc_diamond_reg_bg_2px,
  sp_bronze_reg_bg_2px,
  sp_sapphire_reg_bg_2px,
  sp_diamond_reg_bg_2px,
  tb_bronze_reg_bg_2px,
  tb_sapphire_reg_bg_2px,
  tb_diamond_reg_bg_2px,
  // 1px枠
  pc_bronze_reg_bg_1px,
  pc_sapphire_reg_bg_1px,
  pc_diamond_reg_bg_1px,
  sp_bronze_reg_bg_1px,
  sp_sapphire_reg_bg_1px,
  sp_diamond_reg_bg_1px,
  tb_bronze_reg_bg_1px,
  tb_sapphire_reg_bg_1px,
  tb_diamond_reg_bg_1px,
  bnr_center,
  bnr_right,
  bnr_left,
};

export default Icons;
